import React from "react";
import "./ProgressBar.css"; // Import the CSS

const ProgressBar = () => {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <button
          class="progress-bar-tab active"
          onclick="openTab(event, 'Tab1')"
        >
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
        <button class="progress-bar-tab " onclick="openTab(event, 'Tab1')">
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
        <button class="progress-bar-tab " onclick="openTab(event, 'Tab1')">
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
        <button class="progress-bar-tab " onclick="openTab(event, 'Tab1')">
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
        <button class="progress-bar-tab " onclick="openTab(event, 'Tab1')">
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
        <button class="progress-bar-tab " onclick="openTab(event, 'Tab1')">
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
        <button class="progress-bar-tab " onclick="openTab(event, 'Tab1')">
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
        <button class="progress-bar-tab " onclick="openTab(event, 'Tab1')">
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
        <button class="progress-bar-tab " onclick="openTab(event, 'Tab1')">
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
        <button class="progress-bar-tab " onclick="openTab(event, 'Tab1')">
          <img src="/assets/images/icons/material-symbols_description-outline.svg" />
        </button>
      </div>
    </div>
  );
};

export default ProgressBar;
