import React, { useState, useEffect } from "react";
import "./Stage1.css"; // Optional: Add a CSS file for Stage1-specific styles
import { useNavigate } from "react-router-dom";
import LeaveApplication from "../LeaveApplication";
import EventCalendar from "../EventCalendar";
import JobTable from "./Job/JobTable";
import JobView from "./Job/JobView";
import ProgressBar from "./Job/ProgressBar";
import useApiService from "../../services/useApiService";
const Stage1 = () => {
  const [selectedJob, setSelectedJob] = useState(null); // To store selected job data
  const navigate = useNavigate();

  const [jobData, setJobData] = useState(null); // To store selected job data for JobView
  const [selectedDate, setSelectedDate] = useState({
    day: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const { get, loading, error } = useApiService(); // Use the custom hook for API requests
  const [jobsData, setJobsData] = useState([]); // To store jobs fetched from the API

  // Handle the date change from the calendar
  const handleDateChange = (newDate) => {
    console.log(newDate);
    setSelectedDate(newDate); // Update the selected date
  };

  // Fetch jobs data from API
  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        const data = await get("/api/jobs"); // Call the API
        console.log("Fetched Jobs:", data); // Check the fetched data
        if (data.success) {
          setJobsData(data.data); // Store jobs in state
        }
      } catch (err) {
        console.error("Error fetching jobs data:", err.message);
      }
    };

    fetchJobsData();
  }, [get]);

  // Handle job selection from JobTable
  const handleJobSelect = (job) => {
    setSelectedJob(job); // Update selected job state
  };
  const handleAddJob = () => {
    navigate("/work-implementation/add-new-job");
  };
  return (
    <div className="app-container section">

    <div className="button-container-section-0">
      <button className="custom-button" onClick={handleAddJob}>+ New job creation</button>
      <button className="custom-button">+ New Hiring</button>
      <button className="custom-button">+ New Request</button>
      <button className="custom-button">+ Leave Request</button>
    </div>
      <div className=" section-1 parent">
        <div className="child">
          <LeaveApplication />
        </div>
        <div className="child" style={{  padding: "40px" }}>
          {/* <EventCalendar onDateSelect={handleDateChange} /> */}
          <div style={{ width: "90%", height: "auto", overflow: "hidden" }}>
            <iframe
              src="https://calendar.google.com/calendar/embed?src=shubh.patil153@gmail.com&ctz=Asia/Kolkata"
              style={{ border: 0 }}
              width="100%"
              height="600"
              frameBorder="0"
              scrolling="no"
              title="Google Calendar"
            ></iframe>
          </div>

        </div>
      </div>
      <div className="section-2">
        <JobTable jobsData={jobsData} onJobSelect={handleJobSelect} />
      </div>
      <div className="section-3">
        {selectedJob && <JobView jobData={selectedJob} />}
      </div>
    </div>
  );
};

export default Stage1;
