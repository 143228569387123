import React from "react";
import { useNavigate } from "react-router-dom";

import "./JobView.css";

const JobView = ({ jobData }) => {
  // Destructure jobData for easy access
  const {
    id,
    job_name,
    job_number,
    job_status,
    building_type,
    number_of_floors,
    total_amount,
    drafted_by,
    company_name,
    project_date,
    site_address,
    total_area,
    rate,
    designed_by,
    aadhaar_number,
    pan_number,
    owner_name,
    owner_mobile,
    owner_phone,
    owner_email,
    owner_office,
    architect_name,
    architect_mobile,
    architect_phone,
    architect_email,
    architect_office,
    client_name,
    client_mobile,
    client_phone,
    client_email,
    client_office,
    builder_name,
   builder_mobile,
   builder_phone,
   builder_email,
   builder_office,
  } = jobData;
  const navigate = useNavigate();

  const handleAddJob = () => {
    navigate(`/work-implementation/edit-job/${jobData.id}`);
    // navigate(`/edit-job/${jobData.jobNumber}`);

  };

  return (
    <div className="job-view-container">
      <div className="job-view-container-section-1">
        <div className="job-view-title">
          <p>{job_name}</p>
        </div>
        <div className="job-view-add-action">
          <button>+ Add work</button>
        </div>
        <div className="job-view-edit-action">
          <button onClick={handleAddJob}>
            Edit <img src="/assets/images/icons/edit.svg" alt="Edit" />
          </button>
        </div>
      </div>
      <div className="job-view-container-section-2">
        <div className="job-view-details-container">
          <div className="row">
            <div className="label">Job number</div>
            <div className="value">{job_number}</div>
          </div>
          <div className="row">
            <div className="label">Job name</div>
            <div className="value">Job Name {id}</div>
          </div>
          <div className="row">
            <div className="label">Job status</div>
            <div className="value">
              <select defaultValue={job_status}>
                <option>Certification complete</option>
                <option>Pending</option>
                <option>In Progress</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="label">Building type</div>
            <div className="value">
              <select defaultValue={building_type}>
                <option>Commercial building</option>
                <option>Residential building</option>
                <option>Industrial building</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="label">No. of floors</div>
            <div className="value">{number_of_floors}</div>
          </div>
          <div className="row">
            <div className="label">Total amount</div>
            <div className="value">₹ {total_amount}</div>
          </div>
          <div className="row">
            <div className="label">Drafted by</div>
            <div className="value">{drafted_by}</div>
          </div>
        </div>

        <div className="job-view-vertical-line "></div>
        <div className="job-view-details-container">
          <div className="row">
            <div className="label">Company name</div>
            <div className="value">{company_name}</div>
          </div>
          <div className="row">
            <div className="label">Project date</div>
            <div className="value">{project_date}</div>
          </div>
          <div className="row">
            <div className="label">Site address</div>
            <div className="value">{site_address}</div>
          </div>
          <div className="row">
            <div className="label">Total area</div>
            <div className="value">{total_area} Sq. ft.</div>
          </div>
          <div className="row">
            <div className="label">Rate</div>
            <div className="value">₹ {rate}</div>
          </div>
          <div className="row">
            <div className="label">Designed by</div>
            <div className="value">{designed_by}</div>
          </div>
          <div className="row">
            <div className="label">Aadhaar number</div>
            <div className="value">
              <a href="#view" className="link">
                view
              </a>
              <a href="#upload" className="link">
                upload
              </a>
            </div>
          </div>
          <div className="row">
            <div className="label">PAN number</div>
            <div className="value">
              <a href="#view" className="link">
                view
              </a>
              <a href="#upload" className="link">
                upload
              </a>
            </div>
          </div>
        </div>

        {/* Owner details */}
        <div className="job-view-details-container">
          <div className="row">
            <div className="label">Owner details</div>
            <div className="value"></div>
          </div>
          <div className="row">
            <div className="label">Owner name</div>
            <div className="value">{owner_name}</div>
          </div>
          <div className="row">
            <div className="label">Mobile number</div>
            <div className="value">{owner_mobile}</div>
          </div>
          <div className="row">
            <div className="label">Phone number</div>
            <div className="value">{owner_phone}</div>
          </div>
          <div className="row">
            <div className="label">Email address</div>
            <div className="value">{owner_email}</div>
          </div>
          <div className="row">
            <div className="label">Office address</div>
            <div className="value">{owner_office}</div>
          </div>
        </div>

        <div className="job-view-vertical-line "></div>
        {/* Architect details */}
        <div className="job-view-details-container">
          <div className="row">
            <div className="label">Architect details</div>
            <div className="value"></div>
          </div>
          <div className="row">
            <div className="label">Architect name</div>
            <div className="value">{architect_name}</div>
          </div>
          <div className="row">
            <div className="label">Mobile number</div>
            <div className="value">{architect_mobile}</div>
          </div>
          <div className="row">
            <div className="label">Phone number</div>
            <div className="value">{architect_phone}</div>
          </div>
          <div className="row">
            <div className="label">Email address</div>
            <div className="value">{architect_email}</div>
          </div>
          <div className="row">
            <div className="label">Office address</div>
            <div className="value">{architect_office}</div>
          </div>
        </div>

        {/* Client details */}
        <div className="job-view-details-container">
          <div className="row">
            <div className="label">Client details</div>
            <div className="value"></div>
          </div>
          <div className="row">
            <div className="label">Client name</div>
            <div className="value">{client_name}</div>
          </div>
          <div className="row">
            <div className="label">Mobile number</div>
            <div className="value">{client_mobile}</div>
          </div>
          <div className="row">
            <div className="label">Phone number</div>
            <div className="value">{client_phone}</div>
          </div>
          <div className="row">
            <div className="label">Email address</div>
            <div className="value">{client_email}</div>
          </div>
          <div className="row">
            <div className="label">Office address</div>
            <div className="value">{client_office}</div>
          </div>
        </div>

        <div className="job-view-vertical-line "></div>
        {/* Developer/Builder details */}
        <div className="job-view-details-container">
          <div className="row">
            <div className="label">Developer/Builder details</div>
            <div className="value"></div>
          </div>
          <div className="row">
            <div className="label">Developer name</div>
            <div className="value">{builder_name}</div>
          </div>
          <div className="row">
            <div className="label">Mobile number</div>
            <div className="value">{builder_mobile}</div>
          </div>
          <div className="row">
            <div className="label">Phone number</div>
            <div className="value">{builder_phone}</div>
          </div>
          <div className="row">
            <div className="label">Email address</div>
            <div className="value">{builder_email}</div>
          </div>
          <div className="row">
            <div className="label">Office address</div>
            <div className="value">{builder_office}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobView;
