import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

import "./JobForm.css";
import useApiService from "../../../services/useApiService";
const JobForm = ({ onSubmitSuccess }) => {
  const [formData, setFormData] = useState({
    job_number: "",          // jobNumber
    job_status: "",          // jobStatus
    building_type: "",       // buildingType
    no_of_floors: "",        // noOfFloors
    total_amount: "",        // totalAmount
    drafted_by: "",          // draftedBy
    project_date: "",        // projectDate
    site_address: "",        // siteAddress
    total_area: "",          // totalArea
    area_unit: "sq-ft",      // areaUnit
    rate: "",                // rate
    designed_by: "",         // designedBy
    owner_name: "",          // ownerName
    owner_mobile: "",        // ownerMobile
    owner_phone: "",         // ownerPhone
    owner_email: "",         // ownerEmail
    owner_office: "",        // ownerOffice
    owner_sanction: "",      // ownerSanction
    architect_name: "",      // architectName
    architect_mobile: "",    // architectMobile
    architect_phone: "",     // architectPhone
    architect_email: "",     // architectEmail
    architect_office: "",    // architectOffice
    architect_sanction: "",  // architectSanction
    client_name: "",         // clientName
    client_mobile: "",       // clientMobile
    client_phone: "",        // clientPhone
    client_email: "",        // clientEmail
    client_office: "",       // clientOffice
    client_sanction: "",     // clientSanction
    builder_name: "",        // builderName
    builder_mobile: "",      // builderMobile
    builder_phone: "",       // builderPhone
    builder_email: "",       // builderEmail
    builder_office: "",      // builderOffice
    builder_sanction: "",    // builderSanction
  });
  
  const { id } = useParams();

  const { get, post, put, loading, error } = useApiService();
  const [isEditMode, setIsEditMode] = useState(false);
  const [jobId, setJobId] = useState(null);
  // const [selectedOption, setSelectedOption] = useState('');

  // const handleDropdownChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  useEffect(() => {
    const url = window.location.href;

    if (id) {
      setIsEditMode(true);
      setJobId(id); // Extract job ID from the URL
      fetchJobData(id);
    }
  }, []);
  const fetchJobData = async (jobId) => {
    try {
      const data = await get(`/api/job/${jobId}`);
      setFormData(data.data); // Populate the form with fetched data
    } catch (err) {
      console.error("Failed to fetch job data:", err);
    }
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    const isValid = Object.values(formData).every(
      (value) => typeof value === 'string' ? value.trim() !== "" : value !== null && value !== undefined
    );
    
    if (!isValid) {
      alert("Please fill in all required fields.");
      return;
    }

    // Check if phone and email are valid
    // const phonePattern = /^[0-9]{10}$/;
    // if (
    //   !phonePattern.test(formData.ownerMobile) ||
    //   !phonePattern.test(formData.ownerPhone) ||
    //   !phonePattern.test(formData.architectMobile) ||
    //   !phonePattern.test(formData.architectPhone) ||
    //   !phonePattern.test(formData.clientMobile) ||
    //   !phonePattern.test(formData.clientPhone) ||
    //   !phonePattern.test(formData.builderMobile) ||
    //   !phonePattern.test(formData.builderPhone)
    // ) {
    //   alert("Please enter valid 10-digit phone numbers.");
    //   return;
    // }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const emailFields = [
      formData.owner_email,
      formData.architect_email,
      formData.client_email,
      formData.builder_email
    ];
    
    const isInvalidEmail = emailFields.some(email => !emailPattern.test(email));
    
    if (isInvalidEmail) {
      toast.error("Please enter valid email addresses.");
      // alert("Please enter valid email addresses.");
      return;
    }
    
    try {
      if (isEditMode) {
        await put(`/api/job/${jobId}`, formData);
      } else {
        await post("/api/job/store", formData);
      }
      toast.success("Form submitted successfully.");

      // alert("Form submitted successfully.");
      if (onSubmitSuccess) onSubmitSuccess(); // Callback for success (e.g., navigation)
    } catch (err) {
      toast.error(err);
      console.error("Failed to submit form:", err);
    }
  };

  return (
    <div className="new-job-form container">
      <h2>{isEditMode ? "Edit Job" : "New Job Creation"}</h2>
      <form className="form-container" onSubmit={handleSubmit}>
        {/* Left Column */}
        <div className="form-left">
          <div className="form-group">
            <label htmlFor="job-number">Job number</label>
            <input
              type="text"
              id="job_number"
              value={formData.job_number}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="job-status">Job status</label>
            <select
              id="job_status"
              value={formData.job_status}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Job status
              </option>
              <option value="not started">Not Started</option>
              <option value="ongoing">Ongoing</option>
              <option value="completed">Completed</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="building-type">Building type</label>
            <select
              id="building_type"
              value={formData.building_type}
              onChange={handleChange}
              required
            >
              <option value="">Please select</option>
              <option value="Residential">Residential</option>
              <option value="Commercial">Commercial</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="no-of-floors">No. of floors</label>
            <input
              type="number"
              id="no_of_floors"
              value={formData.no_of_floors}
              onChange={handleChange}
              placeholder="Enter no. of floors"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="total-amount">Total amount (₹)</label>
            <input
              type="text"
              id="total_amount"
              value={formData.total_amount}
              onChange={handleChange}
              placeholder="Enter total amount"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="drafted-by">Drafted by</label>
            <input
              type="text"
              id="drafted_by"
              value={formData.drafted_by}
              onChange={handleChange}
              placeholder="Enter name"
              required
            />
          </div>
        </div>

        {/* Vertical Line Divider */}
        <div className="vertical-line"></div>

        {/* Right Column */}
        <div className="form-right">
          <div className="form-group">
            <label htmlFor="project-date">Project date</label>
            <input
              type="date"
              id="project_date"
              value={formData.project_date}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="site-address">Site address</label>
            <input
              type="text"
              id="site_address"
              value={formData.site_address}
              onChange={handleChange}
              placeholder="Enter site address"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="total-area">Total area</label>
            <div className="total-area-container">
              <input
                type="text"
                id="total_area"
                value={formData.total_area}
                onChange={handleChange}
                placeholder="Enter total area"
                required
              />
              <select
                className="unit-dropdown"
                id="area_unit"
                value={formData.area_unit}
                onChange={handleChange}
                required
              >
                <option value="sq-ft">Sq. ft.</option>
                <option value="sq-m">Sq. m.</option>
                <option value="acre">Acre</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="rate">Rate</label>
            <input
              type="text"
              id="rate"
              value={formData.rate}
              onChange={handleChange}
              placeholder="Enter rate"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="designed-by">Designed by</label>
            <input
              type="text"
              id="designed_by"
              value={formData.designed_by}
              onChange={handleChange}
              placeholder="Enter name"
              required
            />
          </div>
        </div>

        {/* ............................... */}
        {/* Owner Details Section */}
        <div className="form-left">
          <div className="section-title">Owner details</div>
          <div className="section-container">
            <div className="form-group">
              <label htmlFor="owner-name">Owner name</label>
              <input
                type="text"
                id="owner_name"
                value={formData.owner_name}
                onChange={handleChange}
                placeholder="Owner name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="owner-mobile">Mobile number</label>
              <input
                type="text"
                id="owner_mobile"
                value={formData.owner_mobile}
                onChange={handleChange}
                placeholder="Mobile number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="owner-phone">Phone number</label>
              <input
                type="text"
                id="owner_phone"
                value={formData.owner_phone}
                onChange={handleChange}
                placeholder="Phone number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="owner-email">Email address</label>
              <input
                type="email"
                id="owner_email"
                value={formData.owner_email}
                onChange={handleChange}
                placeholder="Email address"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="owner-office">Office address</label>
              <input
                type="text"
                id="owner_office"
                value={formData.owner_office}
                onChange={handleChange}
                placeholder="Office address"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="owner-sanction">Sanction authority</label>
              <input
                type="text"
                id="owner_sanction"
                value={formData.owner_sanction}
                onChange={handleChange}
                placeholder="Sanction authority"
                required
              />
            </div>
          </div>
        </div>

        {/* Vertical Line Divider */}
        <div className="vertical-line"></div>

        {/* Architect Details Section */}
        <div className="form-right">
          <div className="section-title">Architect details</div>
          <div className="section-container">
            <div className="form-group">
              <label htmlFor="architect-name">Architect name</label>
              <input
                type="text"
                id="architect_name"
                value={formData.architect_name}
                onChange={handleChange}
                placeholder="Architect name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="architect-mobile">Mobile number</label>
              <input
                type="text"
                id="architect_mobile"
                value={formData.architect_mobile}
                onChange={handleChange}
                placeholder="Mobile number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="architect-phone">Phone number</label>
              <input
                type="text"
                id="architect_phone"
                value={formData.architect_phone}
                onChange={handleChange}
                placeholder="Phone number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="architect-email">Email address</label>
              <input
                type="email"
                id="architect_email"
                value={formData.architect_email}
                onChange={handleChange}
                placeholder="Email address"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="architect-office">Office address</label>
              <input
                type="text"
                id="architect_office"
                value={formData.architect_office}
                onChange={handleChange}
                placeholder="Office address"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="architect-sanction">Sanction authority</label>
              <input
                type="text"
                id="architect_sanction"
                value={formData.architect_sanction}
                onChange={handleChange}
                placeholder="Sanction authority"
                required
              />
            </div>
          </div>
        </div>

        {/* .................... */}
        {/* Client Details Section */}
        <div className="form-left">
          <div className="section-title">Client details</div>
          <div className="section-container">
            <div className="form-group">
              <label htmlFor="client-name">Client name</label>
              <input
                type="text"
                id="client_name"
                value={formData.client_name}
                onChange={handleChange}
                placeholder="Client name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="client-mobile">Mobile number</label>
              <input
                type="text"
                id="client_mobile"
                value={formData.client_mobile}
                onChange={handleChange}
                placeholder="Mobile number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="client-phone">Phone number</label>
              <input
                type="text"
                id="client_phone"
                value={formData.client_phone}
                onChange={handleChange}
                placeholder="Phone number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="client-email">Email address</label>
              <input
                type="email"
                id="client_email"
                value={formData.client_email}
                onChange={handleChange}
                placeholder="Email address"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="client-office">Office address</label>
              <input
                type="text"
                id="client_office"
                value={formData.client_office}
                onChange={handleChange}
                placeholder="Office address"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="client-sanction">Sanction authority</label>
              <input
                type="text"
                id="client_sanction"
                value={formData.client_sanction}
                onChange={handleChange}
                placeholder="Sanction authority"
                required
              />
            </div>
          </div>
        </div>

        {/* Vertical Line Divider */}
        <div className="vertical-line"></div>

        {/* Developer/Builder Details Section */}
        <div className="form-right">
          <div className="section-title">Developer/Builder details</div>
          <div className="section-container">
            <div className="form-group">
              <label htmlFor="builder-name">Developer/Builder name</label>
              <input
                type="text"
                id="builder_name"
                value={formData.builder_name}
                onChange={handleChange}
                placeholder="Developer/Builder name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="builder-mobile">Mobile number</label>
              <input
                type="text"
                id="builder_mobile"
                value={formData.builder_mobile}
                onChange={handleChange}
                placeholder="Mobile number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="builder-phone">Phone number</label>
              <input
                type="text"
                id="builder_phone"
                value={formData.builder_phone}
                onChange={handleChange}
                placeholder="Phone number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="builder-email">Email address</label>
              <input
                type="email"
                id="builder_email"
                value={formData.builder_email}
                onChange={handleChange}
                placeholder="Email address"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="builder-office">Office address</label>
              <input
                type="text"
                id="builder_office"
                value={formData.builder_office}
                onChange={handleChange}
                placeholder="Office address"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="builder-sanction">Sanction authority</label>
              <input
                type="text"
                id="builder_sanction"
                value={formData.builder_sanction}
                onChange={handleChange}
                placeholder="Sanction authority"
                required
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="button-container">
          <button type="submit">
            {isEditMode ? "Save Changes" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default JobForm;
