// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import MainLayout from './layouts/MainLayout'
import PrivateRoute from './components/PrivateRoute'
import Login from "./pages/Login/Login";
import Stage1 from './components/work-implementation/Stage1';
import Stage2 from './components/work-implementation/Stage2';
import JobForm from './components/work-implementation/Job/JobForm';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/work-implementation/stage-1" element={<Stage1 />} />
        <Route path="/accounts/credits" element={<Credits />} /> */}

        <Route path="/" element={<MainLayout><PrivateRoute><Dashboard /></PrivateRoute></MainLayout>} />
        <Route path="/dashboard" element={<MainLayout><PrivateRoute><Dashboard /></PrivateRoute></MainLayout>} />
        <Route path="/work-implementation/stage-1" element={<MainLayout><PrivateRoute><Stage1 /></PrivateRoute></MainLayout>} />

        <Route path="/work-implementation/add-new-job" element={<MainLayout><PrivateRoute><JobForm /></PrivateRoute></MainLayout>} />
        <Route path="/work-implementation/edit-job/:id" element={<MainLayout><PrivateRoute><JobForm /></PrivateRoute></MainLayout>} />

        <Route path="/work-implementation/stage-2" element={<MainLayout><PrivateRoute><Stage2 /></PrivateRoute></MainLayout>} />

        <Route path="/work-implementation/stage-4" element={<MainLayout><PrivateRoute><Stage1 /></PrivateRoute></MainLayout>} />

        <Route path="/work-implementation/stage-5" element={<MainLayout><PrivateRoute><Stage1 /></PrivateRoute></MainLayout>} />
        <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
      </Routes>
    </Router>
    
  );
}

export default App;
