import React from "react";
import JobAssignForm from "./Job/JobAssignForm";
import ProgressBar from "./Job/ProgressBar";

const Stage2 = () => {
    const jobId = 2; // Example job ID

  return (
    <div>
      <h1>Assign Job</h1>
      <ProgressBar/>
      <JobAssignForm jobId={jobId} />;
    </div>
  );
};

export default Stage2;
