import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./JobTable.css";

function JobTable({ jobsData, onJobSelect }) {
  console.log("Received jobsData in JobTable:", jobsData); // Check if it's being received correctly

  const [jobs, setJobs] = useState(jobsData);
  const [expandedRow, setExpandedRow] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const navigate = useNavigate();

  const handleExpandRow = (jobId) => {
    setExpandedRow(expandedRow === jobId ? null : jobId);
  };

  const handleSort = (key) => {
    console.log(jobs);
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedJobs = [...jobs].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setJobs(sortedJobs);
  };

  const handleAddJob = () => {
    navigate("/work-implementation/add-new-job");
  };

  const handleRowClick = (job) => {
    onJobSelect(job); // Pass selected job to parent component
  };
  // Sync the jobsData prop with the local jobs state
  useEffect(() => {
    if (jobsData && jobsData.length > 0) {
      setJobs(jobsData); // Update local state with the jobsData prop
    }
  }, [jobsData]); // This will run whenever jobsData changes
  return (
    <div className="job-table-container">
      <div className="job-table-header">
        <h2>MY PROJECTS</h2>
        <button className="add-job-button" onClick={handleAddJob}>
          + Add New Job
        </button>
      </div>
      <div className="job-table">
        <div className="job-table-row-header">
          <div onClick={() => handleSort("job_number")}>Job Code ▾</div>
          <div onClick={() => handleSort("project_date")}>Job Date ▾</div>
          <div onClick={() => handleSort("building_type")}>Job Name ▾</div>
          <div onClick={() => handleSort("job_status")}>Job Status ▾</div>
          <div></div>
        </div>
        <div className="job-table-body">
          {jobs.map((job) => (
            <div key={job.id} className="job-table-row">
              <div>{job.job_number}</div>
              <div>{job.project_date}</div>
              <div>Job Name {job.id}</div>
              <div className="status-column">
                <span className="status-text">{job.job_status}</span>
                <button
                  className="expand-button"
                  onClick={() => handleExpandRow(job.id)}
                >
                  {expandedRow === job.id ? (
                    <img src="/assets/images/icons/up-arrow.svg" />
                  ) : (
                    <img src="/assets/images/icons/down-arrow.svg" />
                  )}
                </button>
              </div>
              {expandedRow === job.id && (
                <div className="job-expanded-details">
                  <div className="expanded-row-content">
                    <div className="detail-item">
                      <strong>Job Owner:</strong> {job.owner_name}
                    </div>
                    <div className="detail-item">
                      <strong>Job Value:</strong> {job.total_amount}
                    </div>
                    {/* <div className="detail-item">
                                            <strong>Site Address:</strong> {job.site_address}
                                        </div>
                                        <div className="detail-item">
                                            <strong>Total Area:</strong> {job.total_area} {job.area_unit}
                                        </div> */}
                    <div className="detail-item progress-container">
                      {/* <strong>Progress:</strong> */}
                      <div className="circular-progress">
                        <svg width="48" height="48">
                          <circle
                            cx="24" /* Center of the circle */
                            cy="24"
                            r="19" /* Radius adjusted for the new thickness */
                            stroke="#448AFF99"
                            strokeWidth="10" /* Line thickness set to 10 */
                            fill="none"
                          />
                          <circle
                            cx="24"
                            cy="24"
                            r="19"
                            stroke="#000080"
                            strokeWidth="10"
                            fill="none"
                            strokeDasharray="100"
                            strokeDashoffset={100 - 10}
                          />
                        </svg>

                        {/* job.progress */}
                        <div className="progress-text">{10}%</div>
                      </div>
                    </div>
                    <div className="detail-item view-details">
                      <button
                        className="view-details-button"
                        onClick={() => handleRowClick(job)}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default JobTable;
