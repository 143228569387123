import React, { useState, useEffect } from "react";
import "./JobAssignForm.css";
import useApiService from "../../../services/useApiService";

const JobAssignForm = ({ jobId }) => {
    const [jobData, setJobData] = useState({});
    const [job_id, setJobId] = useState("");
    const [job_code, setJobCode] = useState("");
    const [job_name, setJobName] = useState("");
    const [priority, setPriority] = useState("");
    const { get, post, loading, error } = useApiService();
  
    // Fetch job data based on jobId
    useEffect(() => {
        if (!jobId) return;

        const fetchJobData = async () => {
            try {
                const response = await get(`/api/job/${jobId}`);
                console.log("Fetched job data:", response.data);
                if (response && response.data) {
                    setJobData({
                        code: response.data.job_number,
                        name: response.data.job_status,
                        ...response.data,
                    });
                }
            } catch (error) {
                console.error("Error fetching job data:", error);
            }
        };

        fetchJobData();
    }, [jobId]);
  
    const handlePriorityClick = (level) => {
      setPriority(level);
    };
  
    const handleFormSubmit = async (e) => {
      e.preventDefault();
  
      const form = e.target;
      const work_title = form.querySelector("input[name='work-title']").value;
      const department = form.querySelector("select[name='department']").value;
      const employee = form.querySelector("select[name='employee']").value;
      const hours = form.querySelector("input[name='hours']").value;
      const minutes = form.querySelector("input[name='minutes']").value;
      const documentName = form.querySelector("input[name='document-name']").value;
      const note = form.querySelector("input[name='note']").value;
  
      const formData = {
        job_id: jobData.id,
        job_code: jobData.code,
        job_name: jobData.name,
        work_title,
        department,
        employee,
        timeline: `${hours}:${minutes}`,
        priority,
        documentName,
        note,
      };
  
      console.log("Captured Form Data:", formData);
  
      try {
        const response = await post("/api/assign-work", formData);
        console.log("API Response:", response.data);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };
  

  return (
    <div className="job-assign-form-container">
      <form className="job-assign-form" onSubmit={handleFormSubmit}>
        <div className="job-assign-form-grid">
          <div className="job-assign-form-grid-item padding-5">Job code</div>
          <div className="job-assign-form-grid-item-end padding-5">
            <input type="hidden" name="job_id" value={jobData.id || ""} />
            <input type="hidden" name="job_code" value={jobData.code || ""} />
            #{jobData.code || "Loading..."}
          </div>
          <div className="job-assign-form-vertical-line"></div>
          <div className="job-assign-form-grid-item padding-5">Job name</div>
          <div className="job-assign-form-grid-item-end padding-5">
            <input type="hidden" name="job_name" value={jobData.name || ""} />
            {jobData.name || "Loading..."}
          </div>
        </div>
        <div className="job-assign-form-grid">
          <div className="job-assign-form-grid-item padding-5">Work title</div>
          <div className="job-assign-form-grid-item-end">
            <input type="text" name="work-title" placeholder="Enter work title" />
          </div>
          <div className="job-assign-form-vertical-line"></div>
          <div className="job-assign-form-grid-item padding-5">Department</div>
          <div className="job-assign-form-grid-item-end">
            {" "}
            <select name="department">
              <option value="department1">Department1</option>
              <option value="department2">Department2</option>
            </select>
          </div>
        </div>
        <div className="job-assign-form-grid">
          <div className="job-assign-form-grid-item padding-5">
            Select employee
          </div>
          <div className="job-assign-form-grid-item-end">
            <select name="employee">
              <option value="employee1">Employee 1</option>
              <option value="employee2">Employee 2</option>
            </select>
          </div>
          <div className="job-assign-form-vertical-line"></div>
          <div className="job-assign-form-grid-item padding-5">
            Enter work timeline
          </div>
          <div className="job-assign-form-grid-item-end">
            <div className="timeline-container">
              <input type="number" name="hours" placeholder="Enter hours" min="1" max="12"/>
              <span>:</span>
              <input type="number" name="minutes" placeholder="Enter minutes" min="1" max="60" />
            </div>
          </div>
        </div>
        <div className="job-assign-form-grid-1">
          <div className="job-assign-form-grid-item padding-5">
            Select Priority
          </div>
          <div className="job-assign-form-grid-item">
            <div className="priority-container">
              {["Urgent", "High", "Medium", "Low"].map((level) => (
                <button
                  key={level}
                  className={`priority-btn ${level.toLowerCase()} ${
                    priority === level ? "selected" : ""
                  }`}
                  onClick={() => handlePriorityClick(level)}
                >
                  {level}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="job-assign-form-grid-3">
          <div className="job-assign-form-grid-item ">Upload document</div>
          <div className="job-assign-form-grid-item-end ">
            <input type="text" name="document-name" placeholder="Enter document name" />
          </div>
          <div></div>
          <div className="job-assign-form-grid-item-end">
            <button className="upload-btn">Upload</button>
          </div>
        </div>
        <div className="job-assign-form-grid-4">
          <div className="job-assign-form-grid-item ">Add note/comment</div>
          <div className="job-assign-form-grid-item-end ">
            <input type="text" name="note" placeholder="Enter text" />
          </div>
        </div>
        <div className="">
          <div className="job-assign-form-grid-item-end ">
            <button className="assign-btn" >Assign work</button>
          </div>
        </div>
        </form>
    </div>
  );
};

export default JobAssignForm;
