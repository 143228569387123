// pages/Users.js
import MainLayout from '../layouts/MainLayout';

function Users() {
  return (
    <MainLayout>
      <h1>Users</h1>
    </MainLayout>
  );
}

export default Users;
