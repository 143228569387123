import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isLoggedIn } from '../services/auth';

function PrivateRoute({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    async function checkLoginStatus() {
      const status = await isLoggedIn();
      setLoggedIn(status);
      setIsLoading(false);
    }

    checkLoginStatus();
  }, []);

  if (isLoading) {
    // Optionally show a loader while checking
    return <div>Loading...</div>;
  }

  return loggedIn ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
