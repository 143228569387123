import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import './Sidebar.css';

const Sidebar = () => {
    const menu = [
        {
            title: "Work Implementation",
            icon: "/assets/images/menu/material-symbols_home-work-outline.svg",
            items: [
                { label: "Stage 1", link: "/work-implementation/stage-1" },
                { label: "Stage 2", link: "/work-implementation/stage-2" },
                { label: "Stage 3", link: "/work-implementation/stage-3" },
                { label: "Stage 4", link: "/work-implementation/stage-4" },
                { label: "Stage 5", link: "/work-implementation/stage-5" },
            ],
        },
        {
            title: "HR Admin",
            icon: "/assets/images/menu/fa6-solid_hands-holding.svg",
            items: [
                { label: "All Employees", link: "/hr-admin/all-employees" },
                { label: "Hiring Request", link: "/hr-admin/hiring-request" },
                { label: "Interview", link: "/hr-admin/interview" },
                { label: "On Boarding Process", link: "/hr-admin/onboarding" },
                { label: "Letters & Certificates", link: "/hr-admin/letters-certificates" },
                { label: "Leave Management", link: "/hr-admin/leave-management" },
                { label: "Salary Board", link: "/hr-admin/salary-board" },
                { label: "Discipline Board", link: "/hr-admin/discipline-board" },
                { label: "Admin Module", link: "/hr-admin/admin-module" },
            ],
        },
        {
            title: "Accounts",
            icon: "/assets/images/menu/streamline_bag-rupee.svg",
            items: [
                { label: "Credits", link: "/accounts/credits" },
                { label: "Debits", link: "/accounts/debits" },
            ],
        },
        {
            title: "Reports",
            icon: "/assets/images/menu/mdi-light_chart-bar.svg",
            items: [
                { label: "Project Report", link: "/reports/project-report" },
                { label: "Employee Report (Monthly)", link: "/reports/employee-report" },
            ],
        },
    ];

    return (
        <aside className="sidebar">
            <div className="sidebar__logo">
                <img src="/assets/images/admin-panel-logo.svg" alt="Logo" />
            </div>
            <hr className="sidebar__divider" />
            <nav className="sidebar__menu">
                {menu.map((section, index) => (
                    <div key={index} className="sidebar__section">
                        <h3 className="sidebar__title">
                            <span className="sidebar__icon">
                                <img src={section.icon} alt={`${section.title} Icon`} />
                            </span>
                            {section.title}
                        </h3>
                        <ul className="sidebar__list">
                            {section.items.map((item, itemIndex) => (
                                <li key={itemIndex} className="sidebar__item">
                                    <NavLink
                                        to={item.link}
                                        className={({ isActive }) =>
                                            isActive ? 'sidebar__link sidebar__link--active' : 'sidebar__link'
                                        }
                                    >
                                        {item.label}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </nav>
        </aside>
    );
};

export default Sidebar;
