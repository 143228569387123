import { useState, useCallback } from "react";
import { toast } from 'react-toastify';

const useApiService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Base URL (optional, can be parameterized if needed)
  const baseUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000";

  // Helper to handle API requests
  const request = async (url, method, body = null, customHeaders = {}) => {
    setLoading(true);
    setError(null);
    try {
      const headers = {
        "Content-Type": "application/json",
        ...customHeaders, // Merge with any custom headers
      };

      const token = localStorage.getItem("authToken");
      if (token) {
        headers.Authorization = `Bearer ${token}`; // Append token if available
      }

      const options = {
        method,
        headers,
      };
      if (body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(`${baseUrl}${url}`, options);

      if (!response.ok) {
        const errorData = await response.json();
        // toast.error(errorData.message);
        if(errorData.errors)
          {
            Object.keys(errorData.errors).forEach((field) => {
              // console.log(errorData.errors[field]);
              const errorMessages = errorData.errors[field].join(', ');
              toast.error(`${errorMessages}`);
            });
          }else{
            toast.error(errorData.message);
          }
        throw new Error(errorData.message || "Something went wrong");
      }

      return await response.json();
    } catch (err) {
      // toast.error(err.message);

      setError(err.message);
      throw err; // Re-throw error for caller to handle
    } finally {
      setLoading(false);
    }
  };

  // Memoize the request function
  const get = useCallback(async (url, customHeaders = {}) => {
    return request(url, "GET", null, customHeaders);
  }, []); // Empty dependency array so it's only created once

  const post = useCallback(async (url, body, customHeaders = {}) => {
    return request(url, "POST", body, customHeaders);
  }, []); // Similarly memoize other HTTP methods if needed
  // PUT Request
  const put = async (url, body, customHeaders = {}) => {
    return request(url, "PUT", body, customHeaders);
  };

  // DELETE Request
  const del = async (url, customHeaders = {}) => {
    return request(url, "DELETE", null, customHeaders);
  };
  return { get, post, put,loading, error };
};

export default useApiService;
