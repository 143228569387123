import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useApiService from '../../services/useApiService';
import './Header.css';

const Header = () => {
    const navigate = useNavigate(); // Initialize navigate
    const { post } = useApiService();

    const [user, setUser] = useState({
        name: "Full Name 1",
        username: "guest_user",
    });

    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

    useEffect(() => {
        // Retrieve user data from localStorage
        const userData = localStorage.getItem("userData");
        if (userData) {
            setUser(JSON.parse(userData));
        }
    }, []);

    const handleLogout = async () => {
      
        try {
          const response = await post('/api/auth/logout'); // API endpoint only, base URL handled in the hook
      
          console.log(response.message || 'Successfully logged out'); // Log response message
      
          // Clear user data and token
          localStorage.removeItem('token');
          localStorage.removeItem('userData');
      
          // Redirect to login page
          navigate('/login');
        } catch (error) {
          console.error('Error logging out:', error);
          alert('Failed to log out. Please try again.');
        }
      };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    return (
        <header className="header">
            <div className="search-container">
                <span className="search-icon">
                    <img src='/assets/images/mingcute_search-line.svg' alt="Search Icon"/>
                </span>
                <input type="text" className="search-input" placeholder="Search" />
            </div>

            <div className="header__icons">
                <span className="header__icon">
                    <img src='/assets/images/material-symbols_mail-outline.svg' alt="Mail Icon" />
                </span>
                <span className="header__icon">
                    <img src='/assets/images/carbon_notification-new.svg' alt="Notification Icon" />
                </span>
                <div className="header__profile" onClick={toggleDropdown}>
                    <img
                        src="/assets/images/fluent-emoji_man-light.svg"
                        alt="User Profile"
                        className="header__profile-img"
                    />
                    <span className="header__profile-name">
                        {user.name ? user.name : user.username} <br />
                        <span className="header__profile-role">Admin</span>
                    </span>
                    {isDropdownOpen && (
                        <div className="dropdown-panel">
                            <button
                                type="button"
                                className="dropdown-btn"
                                onClick={() => navigate('/edit-profile')}
                            >
                                Edit Profile
                            </button>
                            <button
                                type="button"
                                className="dropdown-btn"
                                onClick={handleLogout}
                            >
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
