import React from "react";
import "./LeaveApplication.css";

const LeaveApplication = () => {
  // Sample data for leave requests
  const leaveRequests = [
    {
      id: 1,
      date: "mm dd - dd, yyyy",
      leaveType: "Annual leave",
      status: "Approval pending",
    },
    {
      id: 2,
      date: "mm dd - dd, yyyy",
      leaveType: "Annual leave",
      status: "Approved",
    },
    {
      id: 3,
      date: "mm dd - dd, yyyy",
      leaveType: "Annual leave",
      status: "Rejected",
    },
  ];

  const getStatusClass = (status) => {
    switch (status) {
      case "Approval pending":
        return "status-pending";
      case "Approved":
        return "status-approved";
      case "Rejected":
        return "status-rejected";
      default:
        return "";
    }
  };

  return (
    <div className="leave-application-container">
      {/* Header Section */}
      <div className="leave-application-header">
        <h2>Leave application</h2>
        <button className="apply-leave-button">+ Apply leave</button>
      </div>

      {/* Leave Request List */}
      <div className="leave-request-list">
        {leaveRequests.map((request) => (
          <div key={request.id} className="leave-request-card">
            {/* Card Columns */}
            <div className="leave-column">
              <div className="column-title">Date</div>
              <div className="column-content">
                <span className="date">{request.date}</span> <br/>
                <span className="day-type">Full Day</span>
              </div>
            </div>

            <div className="leave-column">
              <div className="column-title">Leave Type</div>
              <div className="column-content">{request.leaveType}</div>
            </div>

            <div className="leave-column">
              <div className="column-title">Status</div>
              <div className={`column-content leave-status ${getStatusClass(request.status)}`}>
                {request.status === "Approval pending" && <span><img src="/assets/images/icons/mingcute_time-line.svg" width="20px"/></span>}
                {request.status === "Approved" && <span><img src="/assets/images/icons/ic_round-check.svg" width="20px"/></span>}
                {request.status === "Rejected" && <span><img src="/assets/images/icons/material-symbols_cancel-outline.svg" width="20px"/></span>}
                <span>{request.status}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeaveApplication;
