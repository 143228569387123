import { jwtDecode } from 'jwt-decode';

export async function isLoggedIn() {
  const token = localStorage.getItem('authToken');
  const baseUrl = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

  if (!token) return false;

  try {
    const { exp } = jwtDecode(token);

    // Check if token is still valid locally
    if (Date.now() < exp * 1000) {
      return true; // Token is valid
    }

    // Token is expired, validate session on the server
    const response = await fetch(`${baseUrl}/api/session-status`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      console.error('Session validation failed:', response.statusText);
      return false;
    }

    const result = await response.json();
    console.log(result.success);
    if(result.success){
      if (result.token) {
        localStorage.setItem('authToken', result.token);
        localStorage.setItem('userData', JSON.stringify(result.user));
      }
      return true; 
    }else{
      return false; 
    }
    // Server response should include { isValid: true/false }
  } catch (error) {
    console.error('Error during login check:', error);
    return false;
  }
}
