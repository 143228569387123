import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useApiService from "../../services/useApiService";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./Login.css";

function Login() {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0); // Cooldown starts at 0 (enabled)
  const [isSendOtpDisabled, setIsSendOtpDisabled] = useState(true);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const { get, post, loading, error } = useApiService();

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    setIsSendOtpDisabled(value.length !== 10 || isNaN(value)); // Enable send OTP button if 10 digits are entered
  };

  const handleOTPChange = (value, index) => {
    if (!/^[0-9]*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move to the next input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOTPBackspace = (event, index) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSendOtp = async () => {
    try {
      // const response = await fetch(`${apiEndpoint}/api/auth/otp/request`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ mobile }),
      // });
      const response = await post('/api/auth/otp/request', { mobile });
      // console.log(response);
      if (!response.success) {
        toast.error("Failed to send OTP");

        throw new Error("Failed to send OTP");
      }
      toast.success(`OTP send to:${mobile}`);
      console.log("OTP sent to:", mobile);
      setIsOtpSent(true);
      setIsSendOtpDisabled(true);
      setResendCooldown(30); // Set cooldown to 30 seconds

      // Start cooldown timer
      const timer = setInterval(() => {
        setResendCooldown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      // console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP. Please try again.");
      // alert("Failed to send OTP. Please try again.");
    }
  };

  const handleResendOTP = async () => {
    if (resendCooldown > 0) return;

    try {
      // Clear the OTP fields when trying to resend
    setOtp(new Array(6).fill(""));
      const response = await post('/api/auth/otp/request', { mobile });

      if (!response.success) {
        toast.error("Failed to Resend OTP");

        throw new Error("Failed to Resend OTP");
      }
      toast.success(`OTP Resend to:${mobile}`);
      console.log("OTP resent to:", mobile);
      setResendCooldown(30);

      // Start cooldown timer
      const timer = setInterval(() => {
        setResendCooldown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      toast.error("Failed to send OTP. Please try again.");

      // console.error("Error resending OTP:", error);
      // alert("Failed to resend OTP. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join("");
    
    if (otpValue.length < 6) {
      toast.error("Please enter a valid OTP.");
      alert("Please enter a valid OTP.");
      return;
    }
  
    try {
      const response = await post('/api/auth/otp/verify', { mobile, otp: otpValue });

      const { token, user } = response;
      if (token) {
        localStorage.setItem('authToken', token);
        localStorage.setItem('userData', JSON.stringify(user));
        toast.success('OTP verified successfully');
        console.log('OTP verified successfully');
        navigate('/dashboard');
      } else {
        toast.error('Token not found in response');
        throw new Error('Token not found in response');
      }
    } catch (error) {
      // console.error("Error verifying OTP:", error);
      toast.error("OTP verification failed. Please try again.");

      // alert("OTP verification failed. Please try again.");
    }
  };
  

  const isLoginButtonEnabled = otp.every((digit) => digit !== "");

  return (
    <div className="login-container">
      <div className="login-left">
        <h2>LOGIN</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          {/* Mobile Number Input */}
          <div className="input-container">
            <label htmlFor="mobile">Mobile Number</label>
            <input
              type="text"
              id="mobile"
              value={mobile}
              onChange={handleMobileChange}
              maxLength={10}
              placeholder="Enter your mobile number"
            />
          </div>

          {/* Send OTP Button */}
          {!isOtpSent && (
            <button
              type="button"
              className="send-otp"
              disabled={isSendOtpDisabled}
              onClick={handleSendOtp}
            >
              Send OTP
            </button>
          )}

          {/* OTP Input */}
          {isOtpSent && (
            <div className="otp input-container">
              <label>Enter OTP</label>
              <div className="otp-container">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    className="otp-box"
                    value={digit}
                    maxLength="1"
                    onChange={(e) => handleOTPChange(e.target.value, index)}
                    onKeyDown={(e) => handleOTPBackspace(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              <button
                type="button"
                className={`resend-otp ${
                  resendCooldown > 0 ? "disabled" : ""
                }`}
                onClick={handleResendOTP}
                disabled={resendCooldown > 0}
              >
                {resendCooldown > 0
                  ? `Resend OTP (${resendCooldown}s)`
                  : "Resend OTP"}
              </button>
            </div>
          )}

          {/* Login Button */}
          <button
            type="submit"
            className="login-button"
            style={{ display: isLoginButtonEnabled ? "block" : "none" }} // Hide the login button until OTP is fully entered
            disabled={!isLoginButtonEnabled}
          >
            LOGIN
          </button>
        </form>
      </div>
      <div className="login-right">
        <img
          src="/assets/images/Structualtants.svg"
          alt="Logo"
          className="logo-container"
        />
        <h3>Structualtants</h3>
        <p>Designs LLP</p>
        <p className="tagline">We make it simple.</p>
        <div className="illustration">
          <img
            src="/assets/images/city_life_gnpr 1.svg"
            alt="Illustration"
            className="illustration-img"
          />
        </div>
      </div>
      <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                />
    </div>
  );
}

export default Login;
